import React, { useEffect, useMemo, useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Button, Col, Row, Input, Tabs, Dropdown } from 'antd';
import BasicContainer from '@copilot/common/components/containers/basic';
import AllCampaignsTable, { CampaignSummaryModel } from './allCampaignsTable';
import { IOrganizationMember } from '@copilot/common/store/models/redux';
import { CampaignType } from '@copilot/data/responses/interface';
import {
	displayCampaignOptions,
	getSimpleCampaignNameValidator,
} from '@copilot/common/utils/campaign';
import CampaignsPageFilters from '../campaigns/ui/filter';
import { CampaignsFilters } from '../campaigns/types';
import CampaignTypeDropdown, { CampaignFilterType } from './campaignTypeDropdown';
import modalManager from '@copilot/common/utils/modalManager';
import { FilterDefinition } from '@copilot/common/components/componentModels/filterDefinition';
import { blue } from '@ant-design/colors';
import { ICampaignStatus, ICampaignType } from '@copilot/data/graphql/_generated';
import { spacingLG, spacingMD, spacingXS } from '@copilot/common/constant/commonStyles';
import { PageContainer } from '@copilot/common/components/containers/page';

export type UserInfo = Readonly<{
	organizationId: string;
	activeMember: IOrganizationMember;
}>;

type CampaignFilterChangeType = (newFilterType: CampaignFilterType) => void;
type TeamMembersFilterChangeType = (newTeamMembersFilter: FilterDefinition[]) => void;
type CreateNewCampaignHandlerType = (name: string, campaignType: CampaignType) => Promise<void>;
type SearchTermChangeType = (searchTerm: string) => void;

export type AllCampaignsPageProps = {
	canCreate: boolean;
	showActiveAndTeamMembers: boolean;
	isLoading: boolean;
	isError: boolean;
	campaignSummaryData: CampaignSummaryModel[];
	campaignFilterType: CampaignFilterType;
	onCampaignFilterChanged: CampaignFilterChangeType;
	teamMembersFilter: FilterDefinition[];
	onTeamMembersFilterChanged: TeamMembersFilterChangeType;
	onCreateNewCampaign: CreateNewCampaignHandlerType;
	campaignNameSearchTerm: string;
	onCampaignNameSearchTermChanged: SearchTermChangeType;
};

const StyledTabs = styled(Tabs)`
	.copilot-tabs-tab .tab-number {
		background-color: ${blue[0]};
	}

	.copilot-tabs-tab .tab-title {
		font-weight: 500;
	}

	.copilot-tabs-tab:not(.copilot-tabs-tab-active) .tab-number {
		background-color: #f0f0f0; /* antd's built-in grey colours are too dark */
	}

	.copilot-tabs-tab:not(.copilot-tabs-tab-active) .tab-title {
		font-weight: normal;
	}
`;

const StyledBasicContainer = styled(BasicContainer)`
	.flag {
		background: #ffd9d9;
	}

	.messageRead {
		background: #f7f7f7;
	}

	.messageUnread {
		font-weight: bold;
	}

	&.pageTable {
		padding: ${spacingLG};
	}
`;

const StyledDiv = styled.div`
	padding: 0px 24px;
`;

const StyledSpan = styled.span`
	color: gray;
	&.time-period {
		text-align: right;
		display: block;
	}
`;

type CampaignTabProps = {
	tabName: string;
	numCampaigns: number;
};

const CampaignTab: React.FC<CampaignTabProps> = ({ tabName, numCampaigns }) => (
	<>
		<span className="tab-title" style={{ fontFamily: 'Basier Square' }}>
			{tabName}{' '}
		</span>
		<span className="tab-number" style={{ padding: '0 8px', borderRadius: '20px' }}>
			{numCampaigns}
		</span>
	</>
);

const ACTIVE_TAB_KEY = 'Active';
const INACTIVE_TAB_KEY = 'Inactive';

/**
 * All Campaign Page presentational component
 * @param props The props.
 */
const AllCampaignsPage: React.FC<UserInfo & AllCampaignsPageProps> = (props) => {
	const {
		canCreate,
		showActiveAndTeamMembers,
		campaignSummaryData,
		isLoading,
		isError,
		campaignFilterType,
		onCampaignFilterChanged,
		teamMembersFilter,
		onTeamMembersFilterChanged,
		onCreateNewCampaign,
		campaignNameSearchTerm,
		onCampaignNameSearchTermChanged,
	} = props;

	const showCampaignNameEditor = (campaignType: CampaignType) =>
		modalManager.openCampaignCreationModal({
			nameEditorValidator: getSimpleCampaignNameValidator(campaignSummaryData),
			onCreate: async (name: string) => {
				await onCreateNewCampaign(name, campaignType);
			},
		});

	const [tab, setTab] = useState<string>(ACTIVE_TAB_KEY);

	// for updating the team member filter
	const [campaignFilters, setCampaignFilters] = useState<CampaignsFilters>({
		teamMembers: teamMembersFilter,
	});
	useEffect(() => {
		setCampaignFilters({
			teamMembers: teamMembersFilter,
		});
	}, [teamMembersFilter]);

	// helper function for filtering by campaign type
	const fnCampaignTypeFilter = (c: CampaignSummaryModel) => {
		return (
			campaignFilterType === CampaignFilterType.All ||
			(c.type === ICampaignType.Prospecting &&
				campaignFilterType === CampaignFilterType.Prospecting) ||
			(c.type === ICampaignType.Nurture && campaignFilterType === CampaignFilterType.Nurture)
		);
	};

	// helper function for filtering by team members
	const fnTeamMemberFilter = (c: CampaignSummaryModel) => {
		const selectedMembers = campaignFilters.teamMembers.filter((member) => member.isVisible);
		if (selectedMembers.length === 0) {
			return true;
		}
		return c.members.some((member) =>
			selectedMembers.some((filteringMember) => member.orgMemberId === filteringMember.key)
		);
	};

	const fnCampaignNameFilter = (c: CampaignSummaryModel) => {
		return c.name.toLowerCase().includes(campaignNameSearchTerm.toLowerCase());
	};

	// helper to apply filtering functions
	const applyAdditionalFilterFns = (campaigns: CampaignSummaryModel[]) => {
		return campaigns
			.filter(fnCampaignTypeFilter)
			.filter(fnTeamMemberFilter)
			.filter(fnCampaignNameFilter);
	};

	// handle campaign filter type change and campaign data change
	const [activeCampaigns, inactiveCampaigns] = useMemo(() => {
		const activeCampaignData = campaignSummaryData.filter(
			(c) => c.status === ICampaignStatus.Enabled
		);
		const inactiveCampaignData = campaignSummaryData.filter(
			(c) => c.status === ICampaignStatus.Disabled
		);

		// local filtering of campaign data based on client's selected filters
		return [
			applyAdditionalFilterFns(activeCampaignData),
			applyAdditionalFilterFns(inactiveCampaignData),
		];
	}, [
		campaignFilterType,
		teamMembersFilter,
		campaignSummaryData,
		campaignFilters,
		campaignNameSearchTerm,
	]);

	return (
		<PageContainer
			header="All Campaigns"
			content={
				<>
					<StyledDiv>
						<StyledBasicContainer>
							<BasicContainer.Content style={{ padding: spacingMD }}>
								<Row align="top" gutter={[8, 0]}>
									<Col span={5}>
										<Input.Search
											placeholder="Search"
											defaultValue={campaignNameSearchTerm ?? ''}
											onChange={(event) => {
												onCampaignNameSearchTermChanged(event.target.value);
											}}
										/>
									</Col>
									{showActiveAndTeamMembers ? (
										<Col>
											<CampaignsPageFilters
												filters={campaignFilters}
												filterUpdateCallbacks={{
													onTeamMemberFilterUpdate: (
														teamMembers: FilterDefinition[]
													) => {
														onTeamMembersFilterChanged(teamMembers);
													},
												}}
											/>
										</Col>
									) : null}
									<Col>
										<CampaignTypeDropdown
											onChange={(ct) => {
												onCampaignFilterChanged(ct);
											}}
										/>
									</Col>
									<Col flex="auto"></Col>
									<Col>
										{canCreate ? (
											<Dropdown
												overlay={displayCampaignOptions(
													showCampaignNameEditor
												)}
												trigger={['click']}
											>
												<Button className="copilot-btn-primary">
													Create Campaign <DownOutlined />
												</Button>
											</Dropdown>
										) : (
											<></>
										)}
									</Col>
								</Row>
							</BasicContainer.Content>
						</StyledBasicContainer>
					</StyledDiv>
					<StyledDiv>
						<StyledBasicContainer className="pageTable">
							<BasicContainer.Content style={{ padding: '0px' }}>
								<Row>
									<Col span={20}>
										<StyledSpan>
											Your connection and reply rates are colour-coded based
											on internal benchmarks.
										</StyledSpan>
									</Col>
									<Col span={4}>
										<StyledSpan className="time-period">
											Last 30 days
										</StyledSpan>
									</Col>
								</Row>
								<Row style={{ paddingTop: spacingXS }}>
									<Col span={24}>
										<StyledTabs
											activeKey={tab}
											onTabClick={(tabKey) => setTab(tabKey)}
										>
											<Tabs.TabPane
												tab={
													<CampaignTab
														tabName="Active"
														numCampaigns={activeCampaigns.length}
													/>
												}
												key={ACTIVE_TAB_KEY}
											>
												<AllCampaignsTable
													data={activeCampaigns}
													//pagination={{ total: data?.totalCount, current: data?.page + 1, pageSize: data.pageSize, showSizeChanger: true }}
													showActiveMembers={showActiveAndTeamMembers}
													isLoading={isLoading}
													isError={isError}
												>
            </AllCampaignsTable>
											</Tabs.TabPane>
											<Tabs.TabPane
												tab={
													<CampaignTab
														tabName="Inactive"
														numCampaigns={inactiveCampaigns.length}
													/>
												}
												key={INACTIVE_TAB_KEY}
											>
												<AllCampaignsTable
													data={inactiveCampaigns}
													//pagination={{ total: data?.totalCount, current: data?.page + 1, pageSize: data.pageSize, showSizeChanger: true }}
													showActiveMembers={showActiveAndTeamMembers}
													isLoading={isLoading}
													isError={isError}
												>
            </AllCampaignsTable>
											</Tabs.TabPane>
										</StyledTabs>
									</Col>
								</Row>
							</BasicContainer.Content>
						</StyledBasicContainer>
					</StyledDiv>
				</>
			}
		/>
	);
};

export default React.memo(AllCampaignsPage);
